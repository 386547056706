import { Container, Label, Error, Description } from './input-group.style';

interface Props {
  label: string;
  customStyle?: string;
  error: string;
  description?: string;
  children?: any;
}

/**
 * InputGroup component
 * @returns
 */
const InputGroup = ({
  children,
  label,
  error,
  description,
  customStyle,
}: Props) => {
  return (
    <Container $customStyle={customStyle}>
      <Label>{label}</Label>
      {children}
      {description ? <Description>{description}</Description> : null}
      {error ? <Error>{error}</Error> : null}
    </Container>
  );
};

InputGroup.defaultProps = {
  label: '',
  error: '',
};

export default InputGroup;
