import styled from 'styled-components';

export const ButtonComponent = styled.button<{ customStyle?: string }>`
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  width: 100%;
  background-color: #5850ec;
  color: white;

  font-weight: 500;
  font-size: 0.93em;
  line-height: 1.12em;

  &:active {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }

  &:focus {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }

  &:disabled {
    opacity: 0.5;
  }

  cursor: pointer;

  ${({ customStyle }) => customStyle || ''}
`;
