import { ButtonComponent } from './default-button.style';

interface Props {
  onClick(e?: any): void;
  children: React.ReactNode;
  disabled?: boolean;
  customStyle?: string;
  type?: 'button' | 'submit';
}

/**
 * Button component
 * @returns
 */
const DefaultButton = ({
  children,
  onClick,
  type,
  customStyle,
  disabled,
}: Props) => (
  <ButtonComponent
    customStyle={customStyle}
    disabled={disabled}
    type={type}
    onClick={onClick}
  >
    {children}
  </ButtonComponent>
);

DefaultButton.defaultProps = {
  customStyle: '',
  children: 'Button',
  disabled: false,
  type: 'button',
  handleClick: () => '',
};

export default DefaultButton;
