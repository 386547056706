import { LinkButtonComponent } from './link-button.style';

interface Props {
  onClick(): void;
  children: string;
  type?: "button" | "submit" | "reset" | undefined;
  testId?: string;
  customStyle: string;
}

/**
 * Button component
 * @returns
 */
const LinkButton = ({ children, type, onClick, customStyle, testId }: Props) => {
  return (
    <LinkButtonComponent
      $customStyle={customStyle}
      data-testid={testId}
      type={type}
      onClick={onClick}
    >
      {children}
    </LinkButtonComponent>
  );
};

LinkButton.defaultProps = {
  customStyle: '',
  type: 'button',
  onClick: () => '',
};

export default LinkButton;
